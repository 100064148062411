import React from "react";

const ReminderList = ({ reminderList, getBackgroundColor, leadDetail }) => {
  return (
    <>
      {reminderList?.length > 0 ? (
        <div className="opinion-table-container">
          <table className="opinion-table">
            <thead>
              <tr>
                <th
                  style={{
                    background: getBackgroundColor(leadDetail?.lead_type),
                  }}
                >
                  ID
                </th>
                <th
                  style={{
                    background: getBackgroundColor(leadDetail?.lead_type),
                  }}
                >
                  schedule_date
                </th>
                <th
                  style={{
                    background: getBackgroundColor(leadDetail?.lead_type),
                  }}
                >
                  description
                </th>
                <th
                  style={{
                    background: getBackgroundColor(leadDetail?.lead_type),
                  }}
                >
                  Created At
                </th>
              </tr>
            </thead>
            <tbody>
              {reminderList?.map((opinion) => (
                <tr key={opinion?.id}>
                  <td>{opinion?.id}</td>
                  <td>{opinion?.schedule_date}</td>
                  <td>{opinion?.description}</td>
                  <td>{new Date(opinion?.created_at).toLocaleString()}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <p style={{ color: "red" }}>No Reminder available.</p>
      )}
    </>
  );
};

export default ReminderList;
