import React, { useEffect, useState } from "react";
import Select from "react-select";
import { useMedflickApi } from "../../context/medflickContext";
import { axiosInstance } from "../../axiosInstance/axiosInstance";
import { Link, useNavigate } from "react-router-dom";

const SelectHospital = ({
  userId,
  patinetId,
  patinetReport,
  patientSpeciality,
  allOpinionSend,
  getBackgroundColor,
  leadDetail,
  encryptId,
  toggleMessage,
  patientConversation,
  formatDate,
}) => {
  const navigate = useNavigate();
  const { getAllHospitals, hospitals } = useMedflickApi();

  const handleChange = () => {
    if (patinetReport.length > 0 && patientSpeciality !== null) {
      navigate(`/send-opinion/${encryptId(userId)}/${encryptId(patinetId)}`);
    } else {
      alert(
        "Please Uplaod a Medical Report and update Medical condition First"
      );
    }
  };

  useEffect(() => {
    getAllHospitals();
  }, []);

  return (
    <>
      <div className="patient-dashboard-opinion">
        <h2>
          Hospital Opinion Request
          <a
            style={{ color: "#fff", cursor: "pointer" }}
            onClick={() => toggleMessage("Opinion")}
          >
            Update Manually
          </a>
          <a
            style={{ color: "#fff", cursor: "pointer" }}
            onClick={handleChange}
          >
            Send Email
          </a>
        </h2>

        {patientConversation
          ?.filter((e) => e.status === "Opinion") // Filter by status
          .map((e) => (
            <div key={e?.id}>
              <p>{e?.health_description}</p>
              <p
                style={{ fontSize: "12px", color: "#ff6800" }}
                dangerouslySetInnerHTML={{
                  __html: formatDate(e?.created_at),
                }}
              />
            </div>
          ))}

        {allOpinionSend?.length > 0 ? (
          <div className="table-container">
            <table className="opinion-table">
              <thead>
                <tr>
                  <th
                    style={{
                      background: getBackgroundColor(leadDetail?.lead_type),
                    }}
                  >
                    ID
                  </th>
                  <th
                    style={{
                      background: getBackgroundColor(leadDetail?.lead_type),
                    }}
                  >
                    Patient Name
                  </th>
                  <th
                    style={{
                      background: getBackgroundColor(leadDetail?.lead_type),
                    }}
                  >
                    Subject
                  </th>
                  <th
                    style={{
                      background: getBackgroundColor(leadDetail?.lead_type),
                    }}
                  >
                    Case History
                  </th>
                  <th
                    style={{
                      background: getBackgroundColor(leadDetail?.lead_type),
                    }}
                  >
                    Health Issue
                  </th>
                  <th
                    style={{
                      background: getBackgroundColor(leadDetail?.lead_type),
                    }}
                  >
                    Hospital Name
                  </th>
                  <th
                    style={{
                      background: getBackgroundColor(leadDetail?.lead_type),
                    }}
                  >
                    Created At
                  </th>
                  <th
                    style={{
                      background: getBackgroundColor(leadDetail?.lead_type),
                    }}
                  >
                    Patient Report
                  </th>
                </tr>
              </thead>
              <tbody>
                {allOpinionSend?.map((opinion) => (
                  <tr key={opinion.id}>
                    <td>{opinion.id}</td>
                    <td>
                      {
                        opinion.description.match(
                          /<strong>NAME:<\/strong> (.*?)<br>/
                        )?.[1]
                      }
                    </td>
                    <td>{opinion?.subject}</td>
                    <td>
                      {opinion?.case_history ? opinion?.case_history : "null"}
                    </td>
                    <td>
                      {opinion?.health_issue ? opinion?.health_issue : "null"}
                    </td>
                    <td>
                      {
                        opinion.description.match(
                          /<strong>HOSPITAL NAME:<\/strong> (.*?)<br>/
                        )?.[1]
                      }
                    </td>
                    <td>{new Date(opinion.created_at).toLocaleString()}</td>
                    <td>
                      <a
                        href={
                          opinion.description.match(
                            /<strong>Patient Report:<\/strong> (.*?)<br>/
                          )?.[1]
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        View Report
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <p style={{ paddingTop: "10px" }}>
            No Opinions Email Send To Hospitals through CRM .
          </p>
        )}
      </div>
    </>
  );
};

export default SelectHospital;
