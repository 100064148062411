import React, { useState } from "react";
import { axiosInstance } from "../../axiosInstance/axiosInstance";

const VisaUploadManual = ({ patinetId, userId }) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleFileChange = async (event) => {
    const files = event.target.files;
    if (files.length > 0) {
      const formData = new FormData();
      formData.append("embassy_file", files[0]);
      formData.append("patient_id", patinetId);
      formData.append("user_id", userId);
      setIsLoading(true);

      try {
        const response = await axiosInstance.post(
          "/visaUploadforPatient",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        alert("File uploaded successfully");
        // getLeadbyId(id);
      } catch (error) {
        console.error("There was an error uploading the file!", error);
        alert("An error occurred while uploading the file");
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <>
      {isLoading && (
        <div className="loading-container">
          <div className="loading-spinner"></div>
        </div>
      )}
      <div className="dashboard-upload-file-box">
        <div className="file-input">
          <img src="/images/2024/01/upload-icon.png" alt="" />
          <input type="file" onChange={handleFileChange} />
          <span className="label" data-js-label="">
            Upload Visa
          </span>
        </div>
      </div>
    </>
  );
};

export default VisaUploadManual;
