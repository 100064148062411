import React from "react";
import { axiosInstance } from "../../axiosInstance/axiosInstance";

const PatinetReportList = ({
  patinetReport,
  formatDate,
  getLeadbyId,
  leadId,
  
}) => {
  const handleDelete = async (id) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this Report?"
    );
    if (confirmDelete) {
      try {
        const response = await axiosInstance.get(`/reportDelete/${id}`);

        alert("Report deleted successfully");
        getLeadbyId(leadId);
      } catch (error) {
        console.error("There was an error deleting the user!", error);
        alert("An error occurred while deleting the user");
      }
    }
  };
  return (
    <>
      <div className="dashboard-upload-reportsbox">
        {patinetReport && patinetReport.length > 0 ? (
          patinetReport.map((e, index) => (
            <div className="dashboard-upload-reports-file" key={e?.id}>
              <a
                className="deleted-icon"
                href="#"
                onClick={() => handleDelete(e.id)}
              >
                <img src="/images/del.png" />
              </a>
              <div className="report-preview">
                {e?.reports.endsWith(".pdf") ? (
                  <embed
                    src={e?.reports}
                    type="application/pdf"
                    alt="Report Preview"
                  />
                ) : (
                  <img src={e?.reports} alt="Report Preview" />
                )}
              </div>
              <a href={e?.reports} target="_blank" rel="noopener noreferrer">
                <div className="upload-reports-text">
                  <h4>
                    Report {index + 1}
                    <a
                      href={e?.reports}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src="/images/2024/01/arrow-icon.png"
                        alt="Arrow Icon"
                      />
                    </a>
                  </h4>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: formatDate(e?.created_at),
                    }}
                  />
                </div>
              </a>
            </div>
          ))
        ) : (
          <p>No report found</p>
        )}
      </div>
    </>
  );
};

export default PatinetReportList;
