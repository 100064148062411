import React, { useState } from "react";
import { axiosInstance } from "../../axiosInstance/axiosInstance";

const MessagePopup = ({
  toggleMessage,
  showMessage,
  patientId,
  userId,
  getConversion,
  patientJourneyId,
  conversationStatus
}) => {
  const [healthIssue, setHealthIssue] = useState("");
  const [query, setQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const validateForm = () => {
    const newErrors = {};
    // if (!healthIssue) newErrors.healthIssue = "Please select a health issue.";
    if (!query) newErrors.query = "Please provide your opinion.";
    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      const errorMessages = Object.values(validationErrors).join("\n");
      alert(errorMessages);
      return;
    }
    setLoading(true);
    try {
      const response = await axiosInstance.post("/patienthealthissue", {
        patient_id: patientId,
        user_id: userId,
        health_issue: healthIssue,
        description: query,
        status: conversationStatus,
      });
      alert("Successfully");
      toggleMessage();
      getConversion(patientId, userId, patientJourneyId);
      setHealthIssue("");
      setQuery("");
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      <div
        className="popup"
        data-popup="popup-1"
        style={{ display: showMessage ? "block" : "none" }}
      >
        <div className="popup-inner2">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="popup-close"
                data-popup-close="popup-1"
                data-dismiss="modal"
                onClick={toggleMessage}
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>

            <h2>Write Opinion here </h2>

            {/* <div className="treatment-form">
              <div className="inputbox">
                <select
                  value={healthIssue}
                  onChange={(e) => setHealthIssue(e.target.value)}
                >
                  <option value="">Health Issue?</option>
                  <option value="yes">Yes</option>
                  <option value="no">No</option>
                </select>
              </div>
            </div> */}

            <div className="treatment-form" style={{ paddingTop: "10px" }}>
              <div className="inputbox">
                <textarea
                  className="querybox"
                  type="textarea"
                  name="query"
                  placeholder="Your Opinion"
                  rows="5"
                  value={query}
                  onChange={(e) => setQuery(e.target.value)}
                  autocomplete="off"
                ></textarea>
              </div>
            </div>
            <button
              type="submit"
              name="en"
              className="home-button"
              onClick={handleSubmit}
              disabled={loading}
            >
              {loading ? "Loading..." : "Submit Now"}
              <img
                src="https://images.medflick.com/images/2023/01/arrow-c.png"
                alt="arrow-Icon"
              />
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default MessagePopup;
