import { axiosInstance } from "../axiosInstance/axiosInstance";
import React, { createContext, useState, useContext, useEffect } from "react";

const GetLeadContext = createContext();

export const GetLeadProvider = ({ children }) => {
  const [allLead, setAllLead] = useState([]);
  const [websiteLead, setWebsiteLead] = useState([]);
  const [totalLead, setTotalLead] = useState("");
  const [status, setStatus] = useState("idle");
  const [error, setError] = useState(null);
  const [leadDetail, setLeadDetail] = useState("");
  const [patienLeadDetail, setPatientLeadDetail] = useState("");
  const [patinetReport, setPatientReport] = useState([]);
  const [patientSpeciality, setPatientSpeciality] = useState("");
  const [patientJourney, setPatientJourney] = useState("");
  const [userLeadDetail, setUserLeadDetail] = useState("");
  const [patientConversation, setPatientConversation] = useState([]);
  const [loading, setLoading] = useState(false);
  const [platformSelect, setPlatformSelect] = useState("");
  const [patientDetail, setPatientDetail] = useState("");
  const [patientPassport, setPatientPassport] = useState([]);
  const [patientOpinion, setPatientOpinion] = useState([]);
  const [totalpage, setTotalPage] = useState("");
  const [page, setPage] = useState(1);
  const [templateSend, setTemplateSend] = useState([]);
  const [allOpinionSend, setAllOpinionSend] = useState([]);
  const [getOpinionUpload, setGetOpinionUpload] = useState([]);
  const [visaInvitationEmail, setVisaInvitationEmail] = useState([]);
  const [visaUploadFile, setVisaUploadFile] = useState([]);
  const [getSendTicket, setGetSendTicket] = useState([]);
  const [visaSubmissionDate, setVisaSubmissionDate] = useState([]);
  const [reminderList, setReminderList] = useState([]);
  const [visaManualList, setVisaManualList] = useState([]);
  // Search State
  const [query, setQuery] = useState("");
  const [results, setResults] = useState([]);
  const [searchLoading, setSearchLoading] = useState(false);

  const [allLoading, setAllLoading] = useState(false);
  const [loadingCount, setLoadingCount] = useState(0);

  const handleLoading = (isLoading) => {
    if (isLoading) {
      setLoadingCount((prevCount) => prevCount + 1);
      setAllLoading(true);
    } else {
      setLoadingCount((prevCount) => {
        const newCount = prevCount - 1;
        if (newCount === 0) {
          setAllLoading(false);
        }
        return newCount;
      });
    }
  };

  const hookForMedflick = async () => {
    try {
      const response = await axiosInstance.post("/insertMedflick");
      console.log("successful");
    } catch (err) {
      console.log(err.message);
    }
  };

  const hookForMedflickWebsite = async () => {
    try {
      const response = await axiosInstance.post("/postWebsiteDataMedflick");
      console.log("successful");
    } catch (err) {
      console.log(err.message);
    }
  };

  const getAllLead = async (
    page,
    startDate = "",
    endDate = "",
    platformSelect = ""
  ) => {
    setLoading(true);
    try {
      // Build the API URL with start and end dates
      let url = `/leads?page=${page}`;
      if (startDate && endDate) {
        url += `&start_date=${startDate}&end_date=${endDate}`;
      }
      if (platformSelect) {
        url += `&lead_type=${platformSelect}`;
      }
      // if (endDate) {
      //   url += `&end_date=${endDate}`;
      // }

      const response = await axiosInstance.get(url);
      setAllLead(response.data.leads);
      setTotalLead(response.data.totalLeads);
      setTotalPage(response.data?.totalPages);

      return response.data;
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  // const getAllLead = async (page) => {
  //   setLoading(true);
  //   try {
  //     const response = await axiosInstance.get(`/leads?page=${page}`);
  //     setAllLead(response.data.leads);
  //     setTotalLead(response.data.totalLeads);
  //     setTotalPage(response.data?.totalPages);

  //     return response.data;
  //   } catch (err) {
  //     setError(err.message);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  useEffect(() => {
    const fetchData = async () => {
      if (query) {
        setSearchLoading(true);
        try {
          const response = await axiosInstance.get(
            `/SearchleadByName?q=${query}`
          );
          setResults(response.data?.data || []);
        } catch (err) {
          console.log("error", err);
        } finally {
          setSearchLoading(false);
        }
      } else {
        getAllLead(page);
      }
    };

    const debounceFetch = setTimeout(() => {
      fetchData();
    }, 500);

    return () => clearTimeout(debounceFetch);
  }, [query, page]);

  const getWebsiteLead = async () => {
    try {
      const response = await axiosInstance.get("/websiteLeadsList");
      setWebsiteLead(response.data.data);
      hookForMedflickWebsite();
    } catch (err) {
      console.log(err.message);
    }
  };

  const getLeadbyId = async (id) => {
    if (id) {
      handleLoading(true);
      try {
        const response = await axiosInstance.get(`/getLeaddetails/${id}`);
        setLeadDetail(response.data.lead_data);
        setPatientLeadDetail(response.data.patient);
        setUserLeadDetail(response.data.user);
        setPatientReport(response.data.patientReport);
        setPatientSpeciality(response.data.patient_speciality);
        setPatientJourney(response.data.patientJourneydata);
      } catch (err) {
        console.log(err.message);
      } finally {
        handleLoading(false);
      }
    }
  };

  const getConversion = async (patientId, userId, patientJourneyId) => {
    if (patientId && userId && patientJourneyId) {
      handleLoading(true);
      try {
        const response = await axiosInstance.get(
          `/getConversationList/${patientId}/${userId}/${patientJourneyId}`
        );
        setPatientConversation(response.data.data);
      } catch (err) {
        console.log(err.message);
      } finally {
        handleLoading(false);
      }
    }
  };

  const getPatientDetail = async (id) => {
    if (id) {
      try {
        const response = await axiosInstance.get(`/patientedit/${id}`);
        setPatientDetail(response.data.patient_data);
      } catch (err) {
        console.log(err.message);
      }
    }
  };

  const getPatientPassport = async (patientId) => {
    if (patientId) {
      handleLoading(true);
      try {
        const response = await axiosInstance.get(`/getpassport/${patientId}`);
        setPatientPassport(response.data?.passportdata);
      } catch (err) {
        console.log("Error", err);
      } finally {
        handleLoading(false);
      }
    }
  };

  const getPatientOpinion = async (patientId) => {
    if (patientId) {
      handleLoading(true);
      try {
        const response = await axiosInstance.get(
          `/get_hoapital_opinion/${patientId}`
        );
        setPatientOpinion(response.data?.opiniondata);
      } catch (err) {
        console.log("Error", err);
      } finally {
        handleLoading(false);
      }
    }
  };

  const getWhatsappTemplateList = async (userId, patientId) => {
    if (userId && patientId) {
      try {
        const response = await axiosInstance.get(
          `/get-double-tick-data/${userId}/${patientId}`
        );
        setTemplateSend(response.data?.data);
      } catch (err) {
        console.log("Error", err);
      }
    }
  };

  const getReminderList = async (patientId, userId) => {
    if (patientId && userId) {
      try {
        const response = await axiosInstance.get(
          `/schedule_post_list/${patientId}/${userId}`
        );
        setReminderList(response.data?.data);
      } catch (err) {
        console.log("Error", err);
      }
    }
  };

  const getopinionSend = async (patientId, userId) => {
    if (patientId && userId) {
      handleLoading(true);
      try {
        const response = await axiosInstance.get(
          `/hospital_listby_patient/${patientId}/${userId}`
        );
        setAllOpinionSend(response.data?.data);
      } catch (err) {
        console.log("Error", err);
      } finally {
        handleLoading(false);
      }
    }
  };

  const getUploadOpinion = async (patientId) => {
    if (patientId) {
      handleLoading(true);
      try {
        const response = await axiosInstance.get(
          `/get_hoapital_opinion/${patientId}`
        );
        setGetOpinionUpload(response.data?.opiniondata);
      } catch (err) {
        console.log("Error", err);
      } finally {
        handleLoading(false);
      }
    }
  };

  const getVisaInvitationEmail = async (patientId, userId) => {
    if (patientId && userId) {
      handleLoading(true);
      try {
        const response = await axiosInstance.get(
          `/visainvitationget/${patientId}/${userId}`
        );
        setVisaInvitationEmail(response.data?.data);
      } catch (err) {
        console.log("Error", err);
      } finally {
        handleLoading(false);
      }
    }
  };

  const getVisaUploadFile = async (patientId, userId) => {
    if (patientId && userId) {
      handleLoading(true);
      try {
        const response = await axiosInstance.get(
          `/PreInvitationGet/${patientId}/${userId}`
        );
        setVisaUploadFile(response.data?.data);
      } catch (err) {
        console.log("Error", err);
      } finally {
        handleLoading(false);
      }
    }
  };

  const getAllSendTicket = async (patientId, userId) => {
    if (patientId && userId) {
      handleLoading(true);
      try {
        const response = await axiosInstance.get(
          `/ticketvisatohospitallist/${patientId}/${userId}`
        );
        setGetSendTicket(response.data?.data);
      } catch (err) {
        console.log("Error", err);
      } finally {
        handleLoading(false);
      }
    }
  };

  const getVisaSubmisiionDate = async (patientId, userId) => {
    if (patientId && userId) {
      handleLoading(true);
      try {
        const response = await axiosInstance.get(
          `/visaDocumentsReceiveList/${patientId}/${userId}`
        );
        setVisaSubmissionDate(response.data?.data);
      } catch (err) {
        console.log("Error", err);
      } finally {
        handleLoading(false);
      }
    }
  };

  const getManualVisalist = async (patientId, userId) => {
    if (patientId && userId) {
      handleLoading(true);
      try {
        const response = await axiosInstance.get(
          `/getVisalistUploadedPatientByAgent/${patientId}/${userId}`
        );
        // setVisaSubmissionDate(response.data?.data);
        setVisaManualList(response.data?.data);
      } catch (err) {
        console.log("Error", err);
      } finally {
        handleLoading(false);
      }
    }
  };

  const displayData = query ? results : allLead;

  return (
    <GetLeadContext.Provider
      value={{
        allLead,
        status,
        error,
        getAllLead,
        getLeadbyId,
        leadDetail,
        totalLead,
        websiteLead,
        getWebsiteLead,
        loading,
        setPlatformSelect,
        platformSelect,
        patienLeadDetail,
        userLeadDetail,
        patinetReport,
        patientSpeciality,
        patientJourney,
        getConversion,
        patientConversation,
        getPatientDetail,
        patientDetail,
        getPatientPassport,
        patientPassport,
        getPatientOpinion,
        patientOpinion,
        totalpage,
        page,
        setPage,
        query,
        setQuery,
        results,
        searchLoading,
        displayData,
        getWhatsappTemplateList,
        templateSend,
        getopinionSend,
        allOpinionSend,
        getUploadOpinion,
        getOpinionUpload,
        allLoading,
        getVisaInvitationEmail,
        visaInvitationEmail,
        getVisaUploadFile,
        visaUploadFile,
        getAllSendTicket,
        getSendTicket,
        getVisaSubmisiionDate,
        visaSubmissionDate,
        getReminderList,
        reminderList,
        getManualVisalist,
        visaManualList,
      }}
    >
      {children}
    </GetLeadContext.Provider>
  );
};

export const useGetLeadApi = () => {
  return useContext(GetLeadContext);
};
