import React, { useEffect, useState } from "react";
import { axiosInstance } from "../../axiosInstance/axiosInstance";
import { useUser } from "../../context/UserContext";
import { useWhatsappApi } from "../../context/whatsappContex";
import LeftSlider from "../../Inc/LeftSlider";
import LeadList from "./LeadList";
import Graph from "../../HomePage/components/Graph";
import { LuRefreshCcw } from "react-icons/lu";
import EditPatient from "../../HomePage/components/EditPatient";
import LeadSearch from "../../common/LeadSearch";
import { useGetLeadApi } from "../../context/getAllLeadContext";

const AdminLeadList = () => {
  const [adminLeadList, setAdminLeadList] = useState([]);
  const { query, results } = useGetLeadApi();
  const [loading, setLoading] = useState(false);
  const { user } = useUser();
  const { teamData, fetchTeamData, postUserIdLeadId } = useWhatsappApi();
  const userId = user?.id;

  const [showEdit, setShowEdit] = useState(false);
  const [selectedPatientId, setSelectedPatientId] = useState(null);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const toggleEdit = (id) => {
    setShowEdit(!showEdit);
    setSelectedPatientId(id);
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      let url = `/getleadsbyagentid/${userId}`;
      if (startDate && endDate) {
        url += `?start_date=${startDate}&end_date=${endDate}`;
      }

      console.log(url);
      const response = await axiosInstance.get(url);
      setAdminLeadList(response.data.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleFilter = () => {
    setStartDate("");
    setEndDate("");
    fetchData();
  };

  useEffect(() => {
    if (userId) {
      fetchData();
      fetchTeamData();
    }
  }, [userId]);

  useEffect(() => {
    if (userId && startDate && endDate) {
      fetchData();
    }
  }, [startDate, endDate, userId]);

  const displayData = query ? results : adminLeadList;
  return (
    <>
      <div className="medflick-mod">
        <LeftSlider role="agent" path="home" />

        <div className="medflick-mod-right">
          <div className="scrollbar-1">
            <Graph adminLead={adminLeadList?.length} />
            <div className="leads-search-doc">
              <h2>Leads</h2>

              <div className="leads-crm-search">
                <LeadSearch />

                <button
                  className="refresh-button"
                  onClick={fetchData}
                  disabled={loading}
                >
                  <LuRefreshCcw className="refresh-icon" />
                  {loading ? "Refreshing..." : "Refresh"}
                </button>
              </div>
            </div>

            <div className="lead-search">
              <input
                type="date"
                className="lead-search-input"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                placeholder="Start Date"
              />
              <input
                type="date"
                className="lead-search-input"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                placeholder="End Date"
              />
              <button className="lead-search-button" onClick={handleFilter}>
                Clear
              </button>
            </div>
            {startDate && endDate && (
              <div className="leads-search-doc" style={{ marginTop: "10px" }}>
                <h2>
                  Total Number of Leads{" "}
                  <span style={{ fontWeight: "bold" }}>{startDate}</span> to{" "}
                  <span style={{ fontWeight: "bold" }}>{endDate}</span> ={" "}
                  <span style={{ color: "#ff6800", fontWeight: "bold" }}>
                    {displayData?.length} Leads
                  </span>
                </h2>
              </div>
            )}

            <div className="leads-patient-box">
              <div className="leads-patient-head">
                <div className="leads-patient-head-box1">Patient Name</div>
                <div className="leads-patient-head-box2">Country</div>
                <div className="leads-patient-head-box3">Source</div>
                <div className="leads-patient-head-box4">Lead Type</div>
                <div className="leads-patient-head-box5">Report Status</div>
                <div className="leads-patient-head-box6">Assign To</div>
                <div className="leads-patient-head-box7">Status</div>
                <div className="leads-patient-head-box8">Action</div>
              </div>

              {/* <LeadList /> */}
              <LeadList
                adminLeadList={displayData}
                userId={userId}
                teamData={teamData}
                toggleEdit={toggleEdit}
                showEdit={showEdit}
              />
            </div>
          </div>
        </div>
      </div>
      <EditPatient
        toggleMessage={toggleEdit}
        showMessage={showEdit}
        selectedPatientId={selectedPatientId}
      />
    </>
  );
};

export default AdminLeadList;
